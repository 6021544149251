exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-pending-approval-tsx": () => import("./../../../src/pages/account-pending-approval.tsx" /* webpackChunkName: "component---src-pages-account-pending-approval-tsx" */),
  "component---src-pages-created-successfully-tsx": () => import("./../../../src/pages/created-successfully.tsx" /* webpackChunkName: "component---src-pages-created-successfully-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investor-materials-tsx": () => import("./../../../src/pages/investor-materials.tsx" /* webpackChunkName: "component---src-pages-investor-materials-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-restore-password-tsx": () => import("./../../../src/pages/restore-password.tsx" /* webpackChunkName: "component---src-pages-restore-password-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-subscription-success-tsx": () => import("./../../../src/pages/subscription-success.tsx" /* webpackChunkName: "component---src-pages-subscription-success-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

