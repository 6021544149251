import './src/styles/reset.css';
import './src/styles/general.css';

import { Amplify } from 'aws-amplify';

const config = {
  aws_project_region: process.env.GATSBY_REGION,
  aws_cognito_region: process.env.GATSBY_REGION,
  aws_user_pools_id: process.env.GATSBY_COGNITO_USER_POOL,
  aws_user_pools_web_client_id: process.env.GATSBY_COGNITO_USER_POOL_CLIENT_ID,
  Auth: {
    region: process.env.GATSBY_REGION,
    userPoolId: process.env.GATSBY_COGNITO_USER_POOL,
    userPoolWebClientId: process.env.GATSBY_COGNITO_USER_POOL_CLIENT_ID,
  },
};

Amplify.configure(config);
